@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

.image-carousel {
  height: 100vh;
  position: relative;

  @include bp.respond-from('lg') {
    flex: 1 1 50%;
  }
}

.image-carousel__wrapper {
  height: 100%;
  width: 100%;
}

.image-carousel__wrapper--static-desktop {
  @include bp.respond-from('lg') {
    display: flex;

    .image-carousel__image {
      flex: 1 1 50%;
      position: unset;
    }
  }
}

.image-carousel__link {
  display: block;
  height: 100%;
  width: 100%;
}

.image-carousel__image {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;

  @include bp.respond-from('lg') {
    opacity: 1;
  }
}

.image-carousel__image--visible {
  opacity: 1;
}

.image-carousel__title {
  align-items: flex-end;
  display: flex;
  inset: 0;
  justify-content: flex-start;
  padding: var(--bottom-padding);
  position: absolute;

  @include bp.respond-from('lg') {
    --bottom-padding: 32px;
  }

  @include bp.respond-between('md', 'lg') {
    --bottom-padding: 28px;
  }

  @include bp.respond-until('md') {
    --bottom-padding: 16px;
  }
}

.image-carousel__title-text {
  display: inline;
  position: sticky;
  bottom: var(--bottom-padding);
  z-index: 1;
}

.image-carousel__title-text--light {
  color: var(--color-surface);
}

.image-carousel__title-text--dark {
  color: var(--color-primary);
}