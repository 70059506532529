@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

.category-carousel {
  --slider-margin: 28px;
  --slider-gap: 4px;

  position: relative;

  &__container {
    width: 100%;
    overflow: hidden;
  }

  &__slides {
    --gaps: calc(var(--slider-gap) * 3);
    --margins: calc(var(--slider-margin) * 2);

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% - (var(--margins) + var(--gaps))) / 4);
    gap: var(--slider-gap);
    width: 100%;
    margin-inline: var(--slider-margin);
  }

  &__navigation {
    --size: 20px;
    --btn-margin: 12px;

    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--size);
    height: var(--size);
    margin: 0;
    outline: 0;
    padding: 0;
    position: absolute;
    text-decoration: none;
    top: 50%;
    touch-action: manipulation;
    transform: translateY(-50%);
    width: var(--size);
    z-index: 1;

    @include bp.respond-from('xl') {
      --slider-margin: 120px;
      --btn-margin: 20px;
    }

    @include bp.respond-between('lg', 'xl') {
      --slider-margin: 80px;
    }

    @include bp.respond-between('md', 'lg') {
      --slider-margin: 40px;
    }

    &__prev {
      left: var(--btn-margin);
    }

    &__next {
      right: var(--btn-margin);
    }
  }
}
