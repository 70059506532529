@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.size-selector__size-button {
  @include button;
  @include button-outline;

  width: 100%;
  padding: 10px 6px;
  border-color: transparent;
  font-size: 14px;
  line-height: 18px;

  @include bp.respond-from('md') {
    min-height: 42px;
  }

  @include bp.respond-until('md') {
    min-height: 40px;
  }
}

.size-selector__size-wrapper {
  margin: 0 0 8px;
}

.size-selector__size-wrapper--disabled {
  .size-selector__size-button {
    @include button-disabled;

    pointer-events: none;
  }
}

.size-selector__size-wrapper--empty {
  .size-selector__size-button {
    opacity: 0;
    pointer-events: none;
  }
}

.size-selector__size-wrapper--selected {
  .size-selector__size-button {
    @include button-primary;
  }
}

.size-selector__singlesize-wrapper {
  margin: 0 0 8px;
  width: 100%;
  height: auto;
}

.size-selector__size-button--single {
  padding: 10px 61px;
}
