@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.sizechart-wrapper {
  height: 100%;
  width: 100%;
}

.sizechart-wrapper__back-button {
  @include button;

  padding: 10px;
  color: var(--color-primary);

  > div {
    font-size: 22px;
  }
}
