@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.card-carousel {
  overflow: hidden;
  position: relative;

  @include bp.respond-from('lg') {
    margin-bottom: 100px;
    margin-left: 74px; // 80px = 6px from inner card + 74px from whole carousel.
  }

  @include bp.respond-between('md', 'lg') {
    margin-left: 14px; // 20px = 14px + 6px from inner card's spacing.
    margin-bottom: 80px;
  }
}

.card-carousel__title {
  @include bp.respond-from('lg') {
    margin-bottom: 25px;
    margin-left: 80px;
  }

  @include bp.respond-between('md', 'lg') {
    margin-bottom: 24px;
    margin-left: 20px;
  }

  @include bp.respond-until('md') {
    text-align: center;
    margin-bottom: 30px;
  }
}

.card-carousel__container {
  display: flex;

  @include bp.respond-until('md') {
    flex-direction: column;
  }
}

.card-carousel__arrow {
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  z-index: 1;

  @include bp.respond-from('md') {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      background-color: var(--color-surface);
    }
  }
}

.card-carousel__arrow:disabled {
  opacity: 0.3;
}

.card-carousel__arrow--prev {
  position: absolute;
  top: 50%;

  @include bp.respond-from('lg') {
    transform: translateY(calc(-50% - 63px)); // 63px is the half of the image text card body.
  }

  @include bp.respond-from('md') {
    transform: translateY(calc(-50% - 61px)); // 61px is the half of the image text card body.
    left: 20px;
  }
}

.card-carousel__arrow--next {
  position: absolute;
  top: 50%;

  @include bp.respond-from('lg') {
    transform: translateY(calc(-50% - 63px));
  }

  @include bp.respond-from('md') {
    transform: translateY(calc(-50% - 61px));
    right: 20px;
  }
}
