@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

// Text banner
.text-banner {
  display: block;

  @include bp.respond-from('lg') {
    max-width: var(--desktop-max-width);
  }

  @include bp.respond-between('md', 'lg') {
    max-width: var(--tablet-max-width);
  }

  @include bp.respond-until('md') {
    max-width: var(--mobile-max-width);
  }
}

// Text banner alignment
.text-banner--desktop-alignment-center {
  @include bp.respond-from('lg') {
    text-align: center;
    margin: 0 auto;
  }
}

.text-banner--desktop-alignment-left {
  @include bp.respond-from('lg') {
    text-align: left;
    margin: 0 auto 0 0;
  }
}

.text-banner--desktop-alignment-right {
  @include bp.respond-from('lg') {
    text-align: right;
    margin: 0 0 0 auto;
  }
}

.text-banner--tablet-alignment-center {
  @include bp.respond-between('md', 'lg') {
    text-align: center;
    margin: 0 auto;
  }
}

.text-banner--tablet-alignment-left {
  @include bp.respond-between('md', 'lg') {
    text-align: left;
    margin: 0 auto 0 0;
  }
}

.text-banner--tablet-alignment-right {
  @include bp.respond-between('md', 'lg') {
    text-align: right;
    margin: 0 0 0 auto;
  }
}

.text-banner--mobile-alignment-center {
  @include bp.respond-until('md') {
    text-align: center;
    margin: 0 auto;
  }
}

.text-banner--mobile-alignment-left {
  @include bp.respond-until('md') {
    text-align: left;
    margin: 0 auto 0 0;
  }
}

.text-banner--mobile-alignment-right {
  @include bp.respond-until('md') {
    text-align: right;
    margin: 0 0 0 auto;
  }
}

// Title
.text-banner__title {
  display: block;

  @include bp.respond-from('lg') {
    margin-bottom: 32px;
  }

  @include bp.respond-until('lg') {
    margin-bottom: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
}

.text-banner__title--no-margin {
  margin-bottom: 0;
}

.text-banner__title-wrapper {
  position: relative;
}

.textbanner__additional-price {
  display: inline;
  vertical-align: super;
  position: relative;

  @include bp.respond-from('lg') {
    font-size: 20px;
    line-height: 28px;
  }

  @include bp.respond-until('lg') {
    font-size: 14px;
    line-height: 20px;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--color-primary);
    position: absolute;

    @include bp.respond-from('md') {
      top: 12px;
      left: 0;
      transform: rotate(-13deg);
    }

    @include bp.respond-until('md') {
      top: 8px;
      left: 1px;
      transform: rotate(-11deg);
    }
  }
}

// Description
.text-banner__description {
  @include bp.respond-from('lg') {
    margin-bottom: 40px;
    padding: 0 30px;
  }

  @include bp.respond-until('lg') {
    margin-bottom: 32px;
  }
}

// Button
.text-banner__button {
  @include button;

  transition: $link-hover-transition;

  @include hover {
    border: 1px solid var(--color-secondary);
  }

  display: none;
  justify-content: center;
  margin: inherit;
  text-decoration: none;
  width: max-content;
}

.text-banner__button--primary {
  @include button-primary;
}

.text-banner__button--secondary {
  @include button-outline;
}

.text-banner__button--desktop-shown {
  @include bp.respond-from('lg') {
    display: flex;
    padding: 11px 20px;
  }
}

.text-banner__button--tablet-shown {
  @include bp.respond-between('md', 'lg') {
    display: flex;
    padding: 11px 20px;
  }
}

.text-banner__button--mobile-shown {
  @include bp.respond-until('md') {
    display: flex;
    padding: 10px 20px;
  }
}
