@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;

.campaign-info {
  width: auto;
  position: fixed;
  z-index: 2;

  @include bp.respond-from('xxl') {
    top: 23px + $header-height-xxl;
    left: 22px;
  }

  @include bp.respond-between('xl', 'xxl') {
    top: 20px + $header-height-xl;
    left: 22px;
  }

  @include bp.respond-between('lg', 'xl') {
    top: 23px + $header-height-lg;
    left: 22px;
  }

  @include bp.respond-from('lg') {
    font-size: 16px;
    line-height: 24px;
  }

  @include bp.respond-until('lg') {
    top: 13px + $header-height-md;
    left: 14px;
    font-size: 13px;
    line-height: 20px;
  }
}
