@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.skeleton-loader__wrapper {
  width: 100%;
  background-color: var(--color-background);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.skeleton-loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: var(--color-surface);

  @include bp.respond-from('lg') {
    width: 41.5%;
    right: 0;
    overflow-y: auto;
  }

  @include bp.respond-until('lg') {
    left: 0;
  }
}

.skeleton-loader__title {
  left: 50%;
  position: absolute;
  text-align: center;

  @include bp.respond-from('xl') {
    padding-left: 90px;
  }

  @include bp.respond-from('lg') {
    width: 100%;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include bp.respond-between('lg', 'xl') {
    padding-left: 20px;
  }

  @include bp.respond-from('md') {
    margin-bottom: 32px;
  }

  @include bp.respond-until('md') {
    margin-bottom: 40px;
  }

  @include bp.respond-until('lg') {
    transform: translateX(-50%);
    top: 74px;
  }
}

.skeleton-loader__product-tiles {
  @include bp.respond-from('xl') {
    padding: 0 90px;
    margin-top: 150px;
  }

  @include bp.respond-between('lg', 'xl') {
    padding: 0 20px;
    margin-top: 150px;
  }

  @include bp.respond-until('lg') {
    padding: 0 25px 0 16px;
    margin-top: 141px;
  }
}

.skeleton-loader__product-tile {
  display: flex;
  position: relative;
  overflow: hidden;

  @include bp.respond-from('lg') {
    margin-bottom: 15px;
  }

  @include bp.respond-until('lg') {
    margin-bottom: 30px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0%) 0%,
      rgba(255, 255, 255, 75%) 50%,
      rgba(255, 255, 255, 0%) 100%
    );
    animation: skeleton-shine 1.4s ease-in-out infinite;
  }
}

.product-tile__image-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: var(--color-background);

  @include bp.respond-from('lg') {
    flex: 0 0 45%;
    max-width: 45%;
  }

  @include bp.respond-until('lg') {
    flex: 0 0 36%;
    max-width: 140px;
    height: 169px;
    width: 140px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0%) 0%,
      rgba(255, 255, 255, 75%) 50%,
      rgba(255, 255, 255, 0%) 100%
    );
    animation: skeleton-shine 1.4s ease-in-out infinite;
  }
}

.product-tile__image-inner {
  @include bp.respond-from('lg') {
    aspect-ratio: 185 / 220;
    padding-bottom: 118.92%;
  }
}

.product-tile__body-content {
  width: 100%;
  padding-top: 15px;
  position: relative;
  overflow: hidden;

  @include bp.respond-from('lg') {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 32px;
  }

  @include bp.respond-until('lg') {
    margin-left: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0%) 0%,
      rgba(255, 255, 255, 75%) 50%,
      rgba(255, 255, 255, 0%) 100%
    );
    animation: skeleton-shine 1.4s ease-in-out infinite;
  }
}

.product-tile__body-inner {
  @include bp.respond-from('lg') {
    display: block;
    width: 100%;
  }
}

@keyframes skeleton-shine {
  0% {
    left: -90%;
  }

  100% {
    left: 160%;
  }
}

.product-tile__title {
  display: block;
  width: 100%;
  height: 17px;
  background-color: var(--color-background);
  border-radius: 4px;
  margin-bottom: 4px;
}

.product-tile__description {
  display: block;
  width: 100%;
  height: 17px;
  background-color: var(--color-background);
  border-radius: 4px;
  margin-bottom: 4px;
}

.product-tile__price {
  display: block;
  background-color: var(--color-background);
  height: 17px;
  width: 36px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.product-tile__footer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.product-tile__select-size {
  display: block;
  width: 108px;
  height: 42px;
  background-color: var(--color-background);
  border-radius: 4px;
  border: 1px solid transparent;
}

.product-tile__edit-link {
  display: block;
  background-color: var(--color-background);
  height: 17px;
  width: 36px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid transparent;

  @include bp.respond-from('lg') {
    margin-left: 10px;
  }
}
