@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.locale-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include bp.respond-from('md') {
    bottom: 40px;
  }

  @include bp.respond-until('md') {
    bottom: 28px;
  }

  .icon {
    font-size: 20px;
    height: 1em;
  }

  &__wrap {
    height: 100%;
  }

  &__span,
  &__button {
    display: inline-block;
    padding: 0 8px;
    color: var(--color-primary);
  }

  &__button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    height: 18px;
    text-transform: capitalize;
    color: var(--color-primary);
    transition: $link-hover-transition;

    @include hover {
      color: var(--color-secondary-variation);
    }

    &:first-of-type {
      position: relative;
      padding-inline-start: 0;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background-color: var(--color-secondary);
      }
    }
  }
}

.locale-display--light {
  color: var(--color-surface);

  &:first-of-type {
    &::after {
      background-color: var(--color-surface);
    }
  }
}

.locale-display__span--footer {
  color: var(--color-surface);
}
