@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;
@use '@susu/headless-commerce/components/Header/Menu/NavigationMenu/mixins.scss' as *;

.country-selector {
  @include search-animation-variables;
  @include menu-variables;
  @include hide-scroll-y;

  background-color: var(--color-surface);
  height: 100%;
  position: relative;

  @include bp.respond-from('md') {
    padding: var(--menu-padding-large);
    padding-block-start: 0;
  }

  @include bp.respond-until('md') {
    padding: var(--menu-padding-small);
    padding-block-start: 0;
  }

  &__top-wrapper {
    background-color: var(--color-surface);
    position: sticky;
    top: 0;

    @include bp.respond-from('md') {
      padding-block: var(--menu-padding-large) 48px;
    }

    @include bp.respond-until('md') {
      padding-block: var(--menu-padding-large) 36px;
    }
  }

  &__back-button {
    display: flex;

    button {
      @include icon-large;

      appearance: none;
      background-color: transparent;
      border: transparent;
      border-radius: 4px;
      cursor: pointer;
      color: var(--color-primary);

      &:focus-visible {
        outline: solid 4px var(--color-secondary-variation);
      }
    }
  }

  &__search {
    margin-block-start: 32px;
    position: relative;

    &__icon {
      @include icon-large;

      position: absolute;
      top: 8px;
      left: 0;
      color: var(--color-primary);
    }

    &__input {
      background-color: transparent;
      border-radius: 0;
      border-width: 0 0 1px;
      opacity: 1;
      outline: none;
      text-align: left;
      width: 100%;

      @include bp.respond-until('md') {
        min-height: var(--item-height-small);
        line-height: var(--item-height-small);
        padding-inline: 28px;
      }

      @include bp.respond-from('md') {
        min-height: var(--item-height-large);
        line-height: var(--item-height-large);
        padding-inline: 32px;
      }

      // stylelint-disable-next-line
      button {
        @include icon-small;
      }

      &-button {
        position: absolute;
        right: 4px;
        top: 10px;
        color: var(--color-primary);
      }
    }

    // stylelint-disable-next-line
    button {
      appearance: none;
      background-color: transparent;
      border: transparent;
      border-radius: 4px;
      cursor: pointer;
      color: inherit;
      font-size: 20px;

      &:focus-visible {
        outline: solid 4px var(--color-secondary-variation);
      }

      // stylelint-disable-next-line
      button {
        @include icon-small;
      }
    }
  }

  &__countries {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
  }

  &__results {
    &__title {
      display: block;
      padding-block-end: 16px;
      text-transform: capitalize;
      color: var(--color-secondary);
    }
  }

  &__country {
    &__link {
      border-radius: 4px;
      line-height: var(--item-height-small);
      color: var(--color-primary);
      text-decoration: none;
      grid-column: 2;
      transition: $link-hover-transition;

      @include hover {
        color: var(--color-secondary-variation);
      }

      &:focus-visible {
        outline: solid 4px var(--color-secondary-variation);
      }

      &.with-criteria {
        grid-column: 1;
      }
    }

    &__initial {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--item-height-small);
      grid-column: 1;
      color: var(--color-primary);
    }
  }
}
