@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

.card {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  position: relative;
  text-decoration: none;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .title {
    align-self: flex-end;
    text-align: center;
    width: 100%;

    @include bp.respond-from('lg') {
      padding-inline: 28px;
      padding-block-end: 24px;
    }

    @include bp.respond-between('md', 'lg') {
      padding-inline: 18px;
      padding-block-end: 24px;
    }

    @include bp.respond-until('md') {
      padding-inline: 12px;
      padding-block-end: 16px;
    }

    &.palette-light {
      color: var(--color-surface);
    }

    &.palette-dark {
      color: var(--color-primary);
    }
  }
}
