@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

.other-category {
  display: flex;
  flex-direction: column;

  &__cards {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(4, 25%);

    @include bp.respond-from('xl') {
      margin-inline: 120px;
    }

    @include bp.respond-between('lg', 'xl') {
      margin-inline: 80px;
    }

    @include bp.respond-between('md', 'lg') {
      margin-inline: 64px;
    }

    @include bp.respond-until('lg') {
      margin-inline: 0;
      gap: 0;
      grid-template-columns: 100%;
    }
  }

  &__title {
    @include bp.respond-from('lg') {
      margin-block-end: 48px;
    }

    @include bp.respond-between('md', 'lg') {
      margin-block-end: 40px;
    }

    @include bp.respond-until('md') {
      margin-block-end: 32px;
    }

    text-align: center;
    width: 100%;
  }
}
