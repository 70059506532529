@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

.preview {
  align-items: center;
  background-color: var(--background-color);
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  &-wrapper {
    height: 100%;
    position: relative;
    width: 100%;

    &-scale {
      inset: 0;
      position: absolute;
    }

    &-position {
      height: 100%;
      width: 100%;
    }
  }

  img {
    will-change: contents;
  }

  &-type {
    &-static {
      picture,
      img {
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
      }
    }

    &-layered {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &-bowtieimage {
        display: flex;
        width: 100%;
        height: 100%;

        img {
          @include bp.respond-from('xl') {
            object-fit: contain;
          }

          @include bp.respond-between('lg', 'xl') {
            object-fit: cover;
          }

          @include bp.respond-between('md', 'lg') {
            object-fit: contain;
          }

          @include bp.respond-until('md') {
            object-fit: cover;
          }
        }
      }
    }
  }
}
