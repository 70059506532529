@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.quote-slider {
  margin-left: auto;
  margin-right: auto;

  @include bp.respond-from('md') {
    ss-carousel::part(dots) {
      bottom: -20px;
    }
  }

  @include bp.respond-from('lg') {
    margin-top: 240px;
    margin-bottom: 240px;
    max-width: 766px;
  }

  @include bp.respond-between('md', 'lg') {
    margin-top: 120px;
    margin-bottom: 120px;
    max-width: 488px;

    ss-carousel::part(dots) {
      text-align: center;
      bottom: -30px;
    }
  }

  @include bp.respond-until('md') {
    margin-top: 140px;
    margin-bottom: 180px;
    max-width: 322px;
  }

  @include bp.respond-until('md') {
    ss-carousel::part(dots) {
      text-align: center;
      bottom: -40px;
    }
  }

  .susu-carousel-item {
    > ss-lazy-image {
      @include bp.respond-from('lg') {
        padding-bottom: 40px;
      }

      @include bp.respond-between('md', 'lg') {
        padding-bottom: 35px;
      }

      @include bp.respond-until('md') {
        padding-bottom: 40px;
      }

      > img {
        @include bp.respond-from('lg') {
          height: 33px;
        }

        @include bp.respond-until('lg') {
          height: 20px;
        }
      }
    }

    > div > p {
      text-align: center;

      @include bp.respond-from('lg') {
        font-size: 42px;
        line-height: 52px;
      }

      @include bp.respond-between('md', 'lg') {
        font-size: 32px;
        line-height: 38px;
        font-style: italic;
        letter-spacing: -0.97px;
      }

      @include bp.respond-until('md') {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
}
