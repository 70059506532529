@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.link {
  --link-color-hover: var(--color-secondary);
  --link-color: var(--color-primary);
  --link-icon-rotation: 0deg;
  --link-height: 20px;

  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  line-height: var(--link-height);
  text-decoration: none;
  width: fit-content;
  transition: $link-hover-transition;

  &:is(button) {
    appearance: none;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    height: auto;
  }

  &:focus-visible {
    outline: solid 4px var(--color-secondary-variation);
  }

  > .container {
    display: flex;
    align-items: center;
    column-gap: 8px;
    min-height: var(--link-height);

    &__fullwidth {
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
  }

  &__full-width {
    width: 100%;
  }

  &__hovereffect:hover {
    color: var(--color-secondary-variation);
  }

  &__theme {
    &-light {
      color: var(--link-color);

      @include hover {
        color: var(--link-color-hover);
      }
    }

    &-dark {
      color: var(--color-surface);

      @include hover {
        color: var(--link-color-hover);
      }
    }
  }

  &__underline {
    &-with {
      text-decoration: underline;
    }
  }

  &__size {
    &-sm {
      --link-height: 20px;
    }

    &-md {
      --link-height: 24px;
    }

    &-lg {
      --link-height: 28px;
    }

    &-xl {
      --link-height: 32px;
    }
  }
}
