@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.switched-location {
  height: 35px;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  z-index: 2;

  @include bp.respond-from('xxl') {
    top: $header-height-xxl;
  }

  @include bp.respond-between('xl', 'xxl') {
    top: $header-height-xl;
  }

  @include bp.respond-between('lg', 'xl') {
    top: $header-height-lg;
  }

  @include bp.respond-until('lg') {
    top: $header-height-md;
  }

  &__content {
    align-items: center;
    background-color: var(--color-primary);
    color: var(--color-surface);
    display: flex;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
    transition: transform $transition;
    transform: translateY(-100%);

    &-visible {
      transform: translateY(0);
    }

    &_txt {
      font-size: 12px;
    }
  }

  &__flag {
    margin-right: 6px;
  }
}
