@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;
@use '@susu/headless-commerce/components/Header/Menu/NavigationMenu/mixins.scss' as *;

.search {
  background-color: var(--color-surface);
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  &-wrapper {
    max-height: 0;
    height: max-content;
    overflow: hidden;
    transition-property: max-height;
  }

  &__results {
    @include hide-scroll-y;

    margin-block-start: 0;
    max-height: 0;
    opacity: 0;
    transition-property: opacity;
    background-color: var(--color-surface);
  }

  &__input {
    @include search-animation-variables;
    @include menu-variables;

    max-width: 0;
    overflow: hidden;
    position: relative;
    transition-property: max-width;

    input {
      background-color: transparent;
      border-radius: 0;
      border-width: 0 0 1px;
      color: var(--color-primary);
      display: block;
      max-height: 0;
      outline: none;
      overflow: hidden;
      width: 100%;
      min-height: var(--item-height-small);
      border-color: var(--color-primary);

      @include bp.respond-until('md') {
        line-height: var(--item-height-small);
        margin-block-start: 22px;
        padding-inline: 28px;
      }

      @include bp.respond-from('md') {
        line-height: var(--item-height-large);
        min-height: var(--item-height-large);
        margin-block-start: 12px;
        padding-inline: 32px;
      }

      /* clear browser added ‘X’ from input */
      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }

      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }

  &__clear {
    @include icon-small;

    background: transparent;
    border: transparent;
    cursor: pointer;
    position: absolute;
    right: 4px;
    bottom: 12px;
    color: var(--color-primary);
  }

  &-inactive {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    .search {
      height: 0;
      pointer-events: none;

      &-wrapper {
        max-height: 0;
        transition-delay: var(--search-time-in);
        transition-duration: var(--search-time-out);
        transition-timing-function: var(--search-ease-out);
      }

      &__input {
        max-width: 0;
        transition-delay: 0ms;
        transition-duration: var(--search-time-out);
        transition-timing-function: var(--search-ease-out);
      }

      &__results {
        max-height: 0;
        opacity: 0;
        transition-delay: var(--search-time-in);
        transition-duration: var(--search-time-out);
        transition-timing-function: var(--search-ease-out);
      }
    }
  }

  &-active {
    .search {
      pointer-events: all;

      &-wrapper {
        max-height: 100vh;
        transition-delay: var(--search-time-in);
        transition-duration: var(--search-time-in);
        transition-timing-function: var(--search-ease-in);
      }

      &__input {
        max-width: 100vw;
        transition-delay: var(--search-time-in);
        transition-duration: var(--search-time-in);
        transition-timing-function: ease-in-out;
      }

      &__results {
        max-height: 100vh;
        opacity: 1;
        transition-delay: var(--search-time-in);
        transition-duration: var(--search-time-in);
        transition-timing-function: var(--search-ease-in);

        @include bp.respond-from('md') {
          margin-block-start: 48px;
        }

        @include bp.respond-until('md') {
          margin-block-start: 36px;
        }
      }
    }
  }

  &__subtitle {
    text-transform: uppercase;
    margin-block: 8px;
    color: var(--color-primary);
  }

  &__sugestion-block {
    padding-block-start: 32px;

    &:first-child {
      padding-block-start: 0;
    }
  }

  &__no-results__title {
    padding-block-end: 10px;
  }

  &__link {
    color: var(--color-primary);
    display: flex;
    line-height: 24px;
    overflow: hidden;
    padding-block: 8px;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: $link-hover-transition;

    @include hover {
      color: var(--color-secondary-variation);
    }
  }
}

.search__loader {
  width: 100%;
  padding-top: 24px;
}
