@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.product-card {
  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
    background: var(--color-background);

    img {
      aspect-ratio: 5/7;
      object-fit: cover;
      width: 100%;
      height: auto;

      @include bp.respond-until('md') {
        aspect-ratio: 9/14;
      }
    }
  }

  &__body {
    @include bp.respond-from('md') {
      padding: 20px;
    }

    @include bp.respond-until('md') {
      padding: 15px;
    }
  }

  &__name {
    color: var(--color-primary);
    display: block;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: $link-hover-transition;

    @include hover {
      color: var(--color-secondary-variation);
    }

    @include bp.respond-from('md') {
      margin-bottom: 6px;
    }

    @include bp.respond-until('md') {
      margin-bottom: 4px;
    }
  }

  &__material {
    @include text-overflow-elipsis;
  }

  &__price {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-block-start: 5px;
  }
}

.product-card__badges {
  position: absolute;
  vertical-align: top;

  @include bp.respond-from('md') {
    width: calc(100% - 34px);
    top: 17px;
    left: 17px;
  }

  @include bp.respond-until('md') {
    width: calc(100% - 16px);
    top: 8px;
    left: 8px;
  }
}

.product-card__badges--customize {
  @include bp.respond-from('md') {
    padding: 7px;
  }

  @include bp.respond-until('md') {
    padding: 6px;
  }

  > img {
    vertical-align: top;
  }
}

.product-card__badges--lookbuilder {
  @include bp.respond-from('md') {
    padding: 5px;
  }

  @include bp.respond-until('md') {
    padding: 4px;
  }
}

.product-card__badge-wrap {
  position: relative;
  display: inline-block;
  background-color: rgba(#fff, 0.5);
  vertical-align: middle;
  margin-right: 4px;
  border-radius: 4px;

  @include bp.respond-from('md') {
    width: 28px;
    height: 28px;
  }

  @include bp.respond-until('md') {
    width: 24px;
    height: 24px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.product-card__badge-label {
  font-weight: 300;
  float: right;
  color: var(--color-primary);
  border-radius: 4px;
  white-space: nowrap;
  background-color: rgba(#fff, 0.5);

  @include bp.respond-from('md') {
    position: absolute;
    right: -5px;
    padding: 7px 8px;
    font-size: 12px;
    line-height: 16px;
  }

  @include bp.respond-until('md') {
    font-size: 10px;
    line-height: 10px;
    padding: 5px 7px;
  }
}
