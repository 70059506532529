@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.logo {
  align-content: center;
  display: grid;
  transition: opacity $transition;

  @include hover {
    opacity: 0.75;
  }
}

.logo__img {
  width: 100%;
}
