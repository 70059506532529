@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;
@use '@susu/headless-commerce/components/Header/Menu/NavigationMenu/mixins.scss' as *;
@use '@susu/headless-commerce/styles/variables.scss' as *;

.configurator {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include bp.respond-from('xxl') {
    padding-top: $header-height-xxl;
  }

  @include bp.respond-between('xl', 'xxl') {
    padding-top: $header-height-xl;
  }

  @include bp.respond-between('lg', 'xl') {
    padding-top: $header-height-lg;
  }

  &__close {
    position: absolute;

    @include bp.respond-from('lg') {
      top: 73px;
      right: calc(30% + 30px);
    }

    @include bp.respond-until('lg') {
      top: 65px;
      right: 19px;
    }

    button {
      @include icon-small;

      appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: var(--color-primary);
    }
  }

  &__preview {
    height: 100%;
    width: 100%;
    background-color: var(--color-background);
    overflow: hidden;

    @include bp.respond-until('lg') {
      padding-top: $header-height-md;
    }
  }

  @include bp.respond-until('md') {
    grid-template-rows: 1fr 188px;
    grid-template-columns: 100%;
  }

  @include bp.respond-between('md', 'lg') {
    grid-template-rows: 1fr 258px;
    grid-template-columns: 100%;
  }

  @include bp.respond-from('lg') {
    grid-template-rows: 100%;
    grid-template-columns: 1fr 30%;
  }
}
