.progress-bar {
  background-color: var(--color-outline);
  height: 1px;
  width: 100%;
}

.progress-bar__progress {
  background-color: var(--color-primary);
  height: 100%;
  transition: width 0.3s ease-out;
  width: var(--progress, 0);
}
