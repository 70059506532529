@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

.main {
    height: 100%;
    
    @include bp.respond-from('xxl') {
        padding-top: 58px;
    }

    @include bp.respond-between('xl', 'xxl') {
        padding-top: 52px;
    }

    @include bp.respond-between('lg', 'xl') {
        padding-top: 50px;
    }

    @include bp.respond-between('md', 'lg') {
        padding-top: 44px;
    }

    @include bp.respond-until('md') {
        padding-top: 44px;
    }
}