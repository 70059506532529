@use '@susu/headless-commerce/styles/variables.scss' as *;
@use '@susu/headless-commerce/styles/mixins.scss' as *;

.size-selector__actions {
  flex-shrink: 0;
  padding: 10px 16px 16px;
  border-top: 1px solid var(--color-background);
  background-color: #fafafa;
}

.size-selector__messages {
  display: flex;
  justify-content: space-between;
  color: var(--color-secondary);
}

.size-selector__buttons-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.size-selector__sizechart-button {
  @include button;
  @include button-outline;

  width: 50%;
  padding: 10px 20px;
  margin-right: 5px;
}

.size-selector__confirm-button {
  @include button;
  @include button-primary;

  width: 50%;
  padding: 10px 20px;
  margin-left: 5px;
}

.size-selector__confirm-button--disabled {
  @include button-disabled;

  pointer-events: none;
}
